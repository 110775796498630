<template>
    <div class="contact">
        <mj-contact />
    </div>
</template>

<script>
    import Contact from "@/components/contact/Contact_components.vue";

    export default {
        components: {
            mjContact: Contact
        }
    };
</script>
<style >
        .contact
    {
        background-color: #ccdae8;
    }
</style>