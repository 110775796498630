<template>
  <b-container fluid>
    <b-row>
      <b-col cols="0" sm="0" md="0" lg="1" xl="2"/>
      <b-col class="contact__container" cols="12" sm="12" md="12" lg="10" xl="8">
        <h1 class="main__heading">{{ contactContent.head }}</h1>
        <swiper :options="swiperOption" class="swiper-container-mods" ref="mySwiper">
          <swiper-slide
            v-for="prices in contactContent.pricing"
            v-bind:key="prices.index"
            class="contact__container_swiper"
            :class="clickedOption === prices.head ? 'contact__container_swiper_onlick' : ''"
          >
            <div
              v-on:click="pickOption(prices.head, prices.message)"
            >
              <p class="contact__container_swiper_head" :class="clickedOption === prices.head ? 'white_header' : ''">{{ prices.head }}</p>
              <p class="contact__container_swiper_body">{{ prices.body }}</p>
              <p class="contact__container_swiper_price" :class="clickedOption === prices.head ? 'white_header' : ''">{{ prices.price }}</p>
            </div>
          </swiper-slide>
          <div class="swiper-pagination show-pagination" slot="pagination"></div>
        </swiper>
        <b-row class="pb-5 text-center" align-v="center">
          <b-col class="pt-3" md="12" lg="8">
            <p class="contactContent__short_text">
              {{ contactContent.short_text }}
            </p>
          </b-col>
          <b-col class="pt-3" md="12" lg="4">
            <b-button @click="moreInfo" pill variant="outline-primary">ASK FOR DETAILED OFFER</b-button>
          </b-col>
        </b-row>
        <b-row class="py-5">
          <b-col>
            <p class="contact__container-more-info-1">
              {{ contactContent.bottom_head }}
            </p>
            <p class="contact__container-more-info-2">
              {{ contactContent.bottom_content }}
            </p>
            <p class="contact__container-more-info-3">
              <span style="margin:0;">{{ contactContent.bottom_contact }}</span>
              <span>{{ contactContent.bottom_contact_email }}</span>
            </p>
          </b-col>
          <b-col>
            <div class="contact__form">
              <div class="contact__form-header">
                <p></p>
                <p class="contact__form-header-text">FORM</p>
                <div @click="clearForm" v-b-tooltip.hover title="Clear form message" class="circle_in_tab"></div>
              </div>
              <b-form @submit="onSubmit" v-if="show" class="contact__form-body">
                <b-form-group id="input-group-5">
                  <b-form-input
                    class="input-little"
                    id="input-5"
                    v-model="form.surname"
                    type="text"
                    autocomplete="nope"
                    placeholder="Your surname"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2">
                  <b-form-input
                    class="input-little"
                    id="input-2"
                    v-model="form.name"
                    type="text"
                    required
                    autocomplete="nope"
                    placeholder="Your name"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-1">
                  <b-form-input
                    class="input-little"
                    id="input-1"
                    v-model="form.email"
                    type="email"
                    required
                    placeholder="Email"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-3">
                  <b-form-textarea
                    id="input-3"
                    v-model="form.message"
                    type="text"
                    required
                    placeholder="Message"
                  ></b-form-textarea>
                </b-form-group>

                <b-button
                  variant="primary"
                  class="clear_btn"
                  size="sm"
                  pill
                  @click="clearForm"
                  v-b-tooltip.hover.right title="Clear form message"
                  >Clear</b-button
                >

                <b-form-group id="input-group-4" class="input-group-4">
                  <b-form-checkbox-group
                    v-model="form.checked"
                    id="checkboxes-4"
                    class="checkboxes-4"
                    required
                    switches
                  >
                    <b-form-checkbox switch value="agree">I agree that you contact me back</b-form-checkbox>
                    <b-button
                      type="submit"
                      variant="outline-primary"
                      class="custom_btn"
                      >SEND MESSAGE</b-button
                    >
                  </b-form-checkbox-group>
                </b-form-group>
              </b-form>
              <b-row v-if="!show" class="contact__form-body align-items-center">
                <b-col>{{contactMsg}}</b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="0" sm="0" md="0" lg="1" xl="2"/>    
    </b-row>
  </b-container>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const ContactJson = GetJson("Contact", "eng");
import GetJson from "@/repository/json/GetJson.js";

import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    FontAwesomeIcon,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      contactMsg: '',
      contactContent: ContactJson,
      form: {
        email: '',
        name: '',
        message: ContactJson.pricing[1].message,
        checked: []
      },
      myIcon: faCircle,
      show: true,
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination"
        },
        navigation: {
          el: ".swiper-pagination",
          clickable: true
        },
        breakpoints: {
          640: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          1199: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        }
      },
      clickedOption: 'STARTUP',
    };
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();
      this.show = false
      this.contactMsg = 'Thank you for contacting us ' + this.form.name + '! We will get back to you as soon as we can.'
      try {
        // this token below is fake ala recaptcha
        this.form.token = '03AGdBq267meY1qVZj9bCNSWuKC2r-Te6r_zP4omulpN7rWLM8eeej31SkqquOnZSef0Qm7pBkE_LHqzn0NS7G9c3pvDAW4tlUYuCYW1B0bozcmC-A_Bmx565-3mBhWQvV-klmjciOpJarBT0e6yd4rAzjUqELRwKKN9QYo2g2bi3DR6'
        this.form.surname = 'Doecon' //'Doesub'
        let intResp = await fetch('https://hook.integromat.com/sd39678hgt4hwvz2j60pyeh0eb3bdq46', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.form)
        })
        setTimeout(() => {
          this.show = true         
          this.form.email = ''
          this.form.name = ''
          this.form.message = ''
          this.form.checked = []
        }, 3000)
      } catch(e) {
        this.contactMsg = 'Something went wrong! Please reload the page and try again'
      }
    },
    moreInfo: function() {
      this.form.message = this.contactContent.ask_more_info
      this.clickedOption = ''
    },
    pickOption: function(id, msg) {
      this.clickedOption = id
      this.form.message = msg
    },
    clearForm: function() {
      this.form.email = ''
      this.form.name = ''
      this.form.message = ''
      this.form.checked = []
      this.clickedOption = ''
    }
  }
};
</script>
<style lang="scss" scoped>

.main__heading {
  color: $blue;
  text-transform: uppercase;
  font-family: "Good Sans Regular";
  padding: 50px 0 50px 0 !important;
}

.swiper-container-mods {
  padding-bottom: 50px;
}

.show-pagination {
  visibility: hidden;
}

.circle_in_tab{
  position: relative;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  color: #3d464f;
  border: 2px solid;
  border-radius: 15px;
  cursor: pointer;
}

.circle_in_tab:hover {
  background-color: #0500ff;;
}

.white_header {
  color: #fff !important;
}

.contact_content {
  color: $blue;
}

.app__content {
  padding: 0 !important;
}

@media (max-width: 768px) {
  .swiper-container-mods {
    padding: 0 25px 50px 25px;
  }
  .show-pagination {
    visibility: visible;
  }
}

.contact__container {
  padding-bottom: 50px;
}

.swiper-container {
  margin: 0;
  overflow: visible;
}

.contact__container_swiper {
  background: #ffffff;
  border: solid 1px #3d464f;
  border-radius: 16px;
  box-shadow: 6px 6px 0px 0px #3d464f;
  cursor: pointer;
}
.contact__container_swiper > div {
  border-radius: 16px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
}
.contact__container_swiper_head {
  letter-spacing: -0.8px;
  font-size: 1.3rem;
  font-family: 'Good Sans Regular';
}
.contact__container_swiper_body {
  text-align: center;
  width: 70%;
  margin: 0 auto;
  font-size: 1.3rem;
  font-family: 'Roboto Regular';
}
.contact__container_swiper_price {
  color: #0500ff;
  text-align: center;
  font-size: 1.3rem;
  font-family: 'Good Sans Regular';
}
.contact__container_swiper_onlick {
  background: #ad83ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 0px 0px #3d464f;
  position: relative;
  top: 6px;
  left: 6px;
}

.contact__container-more-info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 40px;
  margin-bottom: 80px;
}

.contact__container-more-info > button{
    font-family: 'Good Sans Regular';
    }

.checkboxes-4 {
  display: flex;
  justify-content: space-between;
}
.contactContent__short_text {
  margin: 0;
  font-size: 26px;
  align-self: center;
  font-family: 'Roboto Regular';
}

///contact form //
.contact__container-more-info-1 {
  font-size: 26px;
  padding-bottom: 14px;
  font-family: 'Roboto Regular';

}
.contact__container-more-info-2 {
  padding-bottom: 14px;
  font-family: 'Good Sans Regular';
}
.contact__container-more-info-3 {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-family: 'Good Sans Regular';
}

.contact__form {
  background: #ffffff;
  // border-radius: 16px;
  // -webkit-box-shadow: 5px 5px 5px 0px rgba(112, 112, 112, 1);
  // -moz-box-shadow: 5px 5px 5px 0px rgba(112, 112, 112, 1);
  // box-shadow: 5px 5px 5px 0px rgba(112, 112, 112, 1);
  color: #0500ff;
  border: 1px solid #3d464f;

  background: #ffffff;
  border: solid 1px #3d464f;
  border-radius: 16px;
  box-shadow: 6px 6px 0px 0px #3d464f;
}
.contact__form-header {
  background: #e6e8ec;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-radius: 16px 16px 0 0;
  border-bottom: solid 1px #3d464f;
}
.contact__form-header-text {
  margin: 0;
  font-size: 26px;
  letter-spacing: -0.7px;
  text-transform: uppercase;
  font-family: 'Roboto Regular';
}
.contact__form-body {
  font-family: 'Good Sans Regular';
  padding: 30px;
  font-size: 15px;
  min-height: 330px;
}
.form-control {
  border: none;
  border-bottom: 1px solid #0500ff;
  border-radius: 0;
  color: #0500ff;
  font-size: 0.8rem;
}
.form-control:focus {
  border: none !important;
  border-bottom: 2px solid #0500ff !important;
  color: #0500ff !important;
}
.form-control::placeholder {
  color: #0400f050 !important;
}


.input-little {
  width: 50%;
}
.form-control::placeholder {
  color: #0500ff;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #0500ff;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #0500ff;
}
.form-control:focus {
  box-shadow: none;
  border: 1px solid #0500ff;
}

#input-group-5 {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.custom-checkbox {
  align-items: center;
}

.custom-control-label::before {
  border-radius: 0 !important;
  border-color: #0500ff;
}

.custom_btn {
  width: 200px;
  border-radius: 26px;
}

.clear_btn {
  margin-top: -10px;
  margin-bottom: 30px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: rgb(5, 0, 255);
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #aaabaa white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #aaabaa;
  border-radius: 20px;
  border: 1px solid white;
}

</style>
